<!--
 * @Author: wh
 * @Date: 2020-08-04 19:44:31
 * @LastEditTime: 2020-08-06 09:52:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\weighRoomManage\appointOrder\default.vue
-->
<template>
    <div class="appointOrder-default">
        <div class="text-tip">
            <p>
                <span class="refresh-btn" @click="refresh">
                    <span class="iconfont iconshuaxin"></span>
                    <span>刷新页面</span>
                </span>
            </p>
            <p class="more-his">
                <span @click="viewMore">查看历史记录>></span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {},
    methods: {
        viewMore() {
            this.$toast({
                title: true,
                text: '查看历史记录',
                type: 'eject',
                width: '14.4rem',
                height: '90%',
                t_url: 'tableList/index',
                extr: {
                    code: { TableCode: 'truck_clash_history' } },
            });
        },
        refresh() {
            this.$emit('handleRefresh');
        },
    },
};
</script>
<style lang="stylus">
.appointOrder-default
    height 100%
    background url(./../../../assets/images/default/bg_qs.png) no-repeat top center
    background-color #fff
    position relative
    .text-tip
        position absolute
        bottom 20%
        left 0
        width 100%
        text-align center
        .refresh-btn
            background linear-gradient(270deg,rgba(58,150,254,1) 0%,rgba(64,182,255,1) 100%)
            border-radius 2px
            color #ffffff
            padding .08rem .16rem
            cursor pointer
            .iconshuaxin
                margin-right .05rem
        .more-his
            margin-top .4rem
            text-align center
            color #3B97FE
            cursor pointer
</style>